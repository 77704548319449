import React, {useState} from 'react'
import './Contacts.css'
import {useHistory} from 'react-router-dom';
import {Container, Row, Col, Button, Form} from "react-bootstrap"
import {GrNotes} from 'react-icons/gr'
import {IoIosContact} from 'react-icons/io'
function Apply() {

    let history = useHistory();

  return (
    <div className='apply'>
        <Container>
            <Row>
                <div className="search">Search For A Job</div>
            </Row>
            <Row>
                <div className="pros">
                   <Row>
                    <Col xs={12} md={4}>
                        <div style={{fontSize:25, fontWeight: 550}}>React Developer</div>
                        <div style={{fontSize:20, marginTop:15}}>NASScript</div>
                        <div style={{fontSize:20, marginTop:15}}>Koottupatha, Pattambi, India</div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div style={{display : 'flex'}}>
                            <div><GrNotes size={20}/></div>
                            <div style={{marginLeft:5, fontSize:18}}>Experience</div>
                            </div>
                            <div style={{fontSize:18, marginTop:15, marginLeft:5}}> 1 YEAR</div>
                    </Col>
                    <Col xs={12} md={3}>
                    <div style={{display : 'flex'}}>
                            <div><IoIosContact size={25}/></div>
                            <div style={{marginLeft:5, fontSize:18}}>vacancies</div>
                            </div>
                            <div style={{fontSize:18, marginTop:15, marginLeft:50}}> 1 </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div onClick={() => history.push('/developer')} style={{marginTop:30}}>
                        <Button style={{backgroundColor: 'black'}}>Apply</Button>
                        </div>
                    </Col>
                    </Row> 
                </div>
            </Row>

            <Row>
                <div className="pros">
                   <Row>
                    <Col xs={12} md={4}>
                        <div style={{fontSize:25, fontWeight: 550}}>.NET Developer C#</div>
                        <div style={{fontSize:20, marginTop:15}}>NASScript</div>
                        <div style={{fontSize:20, marginTop:15}}>Koottupatha, Pattambi, India</div>
                    </Col>
                    <Col xs={12} md={3}>
                        <div style={{display : 'flex'}}>
                            <div><GrNotes size={20}/></div>
                            <div style={{marginLeft:5, fontSize:18}}>Experience</div>
                            </div>
                            <div style={{fontSize:18, marginTop:15, marginLeft:5}}> 1 YEAR</div>
                    </Col>
                    <Col xs={12} md={3}>
                    <div style={{display : 'flex'}}>
                            <div><IoIosContact size={25}/></div>
                            <div style={{marginLeft:5, fontSize:18}}>vacancies</div>
                            </div>
                            <div style={{fontSize:18, marginTop:15, marginLeft:50}}> 1 </div>
                    </Col>
                    <Col xs={12} md={2}>
                        <div onClick={() => history.push('/developer')} style={{marginTop:30}}>
                        <Button style={{backgroundColor: 'black'}}>Apply</Button>
                        </div>
                    </Col>
                    </Row> 
                </div>
            </Row>
        </Container>
    </div>
  )
}

export default Apply