import React, {useState} from 'react'
import './About.css'
import {Container, Row, Col, Form} from "react-bootstrap"
import menu from '../../Assets/Images/menu icons.svg';
import Gradient1 from '../../Assets/Images/Gradient red.svg';
import logo1 from '../../Assets/Images/Nlogo_White_footage.svg';
import line from '../../Assets/Images/Line Design.svg';
import logo11 from '../../Assets/Images/Nlogo_White_footage.svg';
import {ImCross} from 'react-icons/im';
import {useHistory} from 'react-router-dom';
import shaik from "../../Assets/Images/shaik.jpeg"
import anas from "../../Assets/Images/anas.jpeg"

function About() {

	let history = useHistory();
	const [menu1, setMenu1] = useState(false);

	const menuOpen = () =>{
		 setMenu1(true)
	}

	const menuClose = () => {
	 setMenu1(false)
	}

	const [login, setLogin] = useState(false);

	const loginOpen= () =>{
	 setLogin(true)
}

  return (
    <div>
          <div className='about'>
				<Container fluid>
					<Row>
						<div style={{display: 'flex', justifyContent:'space-between'}}>
							<div ><img src={logo1} className='log' style={{width:40}} /></div>
							<div style={{display:'flex'}}>
								<div style={{color:'white', marginTop:8, marginRight:15}}>About</div>
								<div onClick={menuOpen}><img className='menucls1' src={menu} style={{width:35}} /></div>
							</div>
						</div>
					</Row>
					<Row>
						<Col xs={12} md={6}>
						<div className='css-typing2' style={{marginTop:170, fontSize: 49, fontWeight:'medium', color:'white'}}>
							<div>Delivering digital<br /></div>
							<div className='work'>workplace solutions<span style={{color:'red', fontSize:'50px', fontWeight: 800}}>.</span></div>
						</div> 
						</Col>
						<Col xs={12} md={6}>
							<img className='grad1' src={Gradient1} style={{width:450, marginTop:70}} />
						</Col> 
					</Row>
					
				</Container>
		  </div>

		  {menu1 ?
				<div className='menupage'>
					<Row>
					<Col xs={12} md={2}>
					<div style={{display: 'flex', justifyContent:'space-between'}}>
						<div ><img src={logo11} className='log' style={{width:40}} /></div>
						
					</div>

					<div onClick={() => history.push('/')} style={{fontSize:35, marginTop:40, color: 'white', cursor: 'pointer'}}>Home</div>
					<div  style={{fontSize:35, marginTop:30, color: 'red',cursor: 'pointer'}}>About</div>
					<div onClick={() => history.push('services')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>Services</div>
					<div onClick={() => history.push('contacts')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>Contact</div>
					<div onClick={() => history.push('blog')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>Blog</div>
					<div onClick={loginOpen} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}> Login</div>
					</Col>
					<Col xs={12} md={2}></Col>
					<Col xs={12} md={7}>
					<div style={{marginTop:80}}>
						<Row style={{positon:'relative'}}>
						<Col xs={12} md={4} lg={3}>
							<div><img src={anas}style={{width:150, height:150, borderRadius:80, marginTop:20}}/></div>
						</Col>
							<Col xs={12} md={8} lg={9}>
								<div  style={{fontSize:20, marginTop:30, color: 'white'}}>"You don't learn to walk by following rules.
								You learn by doing and falling over."</div>
								<div style={{fontSize:15, color: 'white', marginTop:5}}>Anas Athanikkal </div> 
							  <div style={{fontSize:14, color: 'white',  marginTop:5}}>CEO</div>
							</Col>
							
						</Row>
						<div style={{marginTop:50}}>
						<Row>
							<Col xs={12} md={8} lg={9}>
								<div  style={{fontSize:20, marginTop:30, color: 'white'}}>"You don't learn to walk by following rules.
								You learn by doing and falling over."</div>
								<div style={{fontSize:15, color: 'white', textAlign:'end'}}>Ibrahim Abdullah M H Al-Hail</div> 
							  <div style={{fontSize:14, color: 'white', textAlign:'end'}}>Chairman</div>
							</Col>
							<Col xs={12} md={4} lg={3} >
								<div><img src={shaik}style={{width:150, height:150, borderRadius:80, marginTop:20}}/></div>
							</Col>
						</Row>
						</div>
						</div>   
					</Col>
					<Col xs={12} md={1}>
					<div onClick={menuClose}>	
							<ImCross size={35} style={{color: 'white'}}/>
					</div>
					</Col>
					</Row>
				{login ?
					<div className='admcl'>
					<div onClick={() => history.push('login')} style={{cursor: 'pointer'}}>Admin Login</div>
					<div onClick={() => history.push('clogin')} style={{marginTop:10,cursor: 'pointer'}}>Client Login</div>
				</div>: null}
				</div>: null}

        <div className='about1'>
            <Row>
				<Col xs={12} lg={10}>
					<p className='embu' style={{fontSize:48, fontWeight:'550', marginTop:80}}>
						 <span style={{color:'red'}}>"</span>Empowering organizations to digitize their
						  business and create a global impact".
					</p>
			
					<p className='parag' style={{fontSize:20, fontWeight:300, marginTop: 75, marginBottom:75}}>
						NASSCRIPT is a software development company offering a wide range of
						services worldwide. Our main objective is to furnish high-quality fast Services
						at a low cost. We planned to start this company during the year 2015 by 
						collecting details and funds to make this a new venture. After all, it was
						launched in 2020 under the name 'NAS International Group of Company'. The 
						company officially registered its trademark as 'NASSCRIPT' and starts working.
					</p>
				</Col>
				<Col style={{position: 'relative'}} xs={12} md={2}>
					<div className="lineimg">
						<img className="lineimage" src={line} style={{width:775}} />
					</div>

				</Col>
			</Row>
        </div>
		<div className="hrdiv">
			<div className='about2'>	
				<Row>
					<Col xs={12} md={5}>
					<div className="a" style={{color:'red', fontSize:25}}>What we do ?</div>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>Design & Branding</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						It's to fabricate a distinctive identity in comparison to the
						other brands in the market and also providing impetus to the
						product defferentiation.
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
			<div className='about2'>	
				<Row>
				<Col xs={12} md={5}>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>Fully Secured</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						Effective IT security for preventing and protecting your
						applications and network.
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
			<div className='about2'>	
				<Row>
				<Col xs={12} md={5}>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>High Perfomance</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						We help build mobile and web products for both buisiness
						and social purposes in high perfomance.Delivering high
						perfomanceand scalable enterprise mobile apps in Android
						and iOS.
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
			<div className='about2'>	
				<Row>
				<Col xs={12} md={5}>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>Best Securities</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						manage and ensure the overall security of your application
						and network.
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
			<div className='about2'>	
				<Row>
				<Col xs={12} md={5}>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>Trusted Service</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						Our expertise is more reliable in performing tasks in an 
						extraordinary manner.
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
			<div className='about2'>	
				<Row>
				<Col xs={12} md={5}>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>Info Technology</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						it develops practical skills through the creation of products or
						solutions.
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
			<div className='about2'>	
				<Row>
				<Col xs={12} md={5}>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>24/7 Support</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						We have an excellent team to understand your business
						requirments, develop top grade scripts and instruct the team
						exhaustively.
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
			<div className='about2'>	
				<Row>
				<Col xs={12} md={5}>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>IT Management</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						it ensures that all technology resoources and associated
						employees are utilized properly and in a manner that provides
						value.
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
			<div className='about2'>	
				<Row>
				<Col xs={12} md={5}>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>Certified Company</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						It outlines how to implement an information security
						managment system and identifies the mandatory
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
			<div className='about2' >	
				<Row>
				<Col xs={12} md={5}>
					<div className="abt" style={{color:'white', fontSize:48, fontWeight:'medium'}}>Data Analytics</div>
					<div className="prag" style={{color:'white', fontSize:18, marginTop:20}}>
						it's the science of scrutinizing raw data in order to make
						closure about that information
					</div>
					</Col>
				</Row>
			</div>
			<hr style={{height:2, color:'white'}} />
		</div>
    </div>
  )
}

export default About