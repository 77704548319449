import React from 'react'
import './Login.css'
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import logo1 from '../../Assets/Images/Nlogo_black_s.svg';
import logons from '../../Assets/Images/logonas.svg';
import nastag from '../../Assets/Images/Nastag.svg';

function Login() {
  return (
    <div className="logindiv" style={{height: 760}}>
        <Row>
            <Col className="col-1" xs={12} md={6}>
            <div className="col1div">
                <div className='logmndiv' style={{display: 'flex', justifyContent:'space-between'}}>
                                <div ><img src={logo1} style={{width:40}}/></div>
                                
                </div> 
                <div className='logmndiv1' style={{display: 'flex', justifyContent:'space-between'}}>
                                <div ><img src={logo1} style={{width:40}}/></div>
                                <div><img src={nastag} style={{width:150}}/></div>
                                
                </div> 
                <div className='bxinsdiv'>
            <div className='box-inside'>
            <div style={{fontSize:25, fontWeight:600, color:'black', textAlign:'center'}}>Admin Login</div>
            
            <div style={{marginTop:20}}>
            <Form>
              <Form.Group className="mb-1" controlId="formBasicEmail"> 
                  <Form.Control 
                      type="email"
                      className='shadow12' 
                      // value={email}
                      // onChange={(e) => {setEmail(e.target.value)}} 
                      placeholder='Username' />
              </Form.Group>
           </Form>
            </div>
            
            <div style={{marginTop:20}}>
            <Form>
              <Form.Group className="mb-1" controlId="formBasicEmail">
                 
                  <Form.Control 
                      type="password"
                      className='shadow12' 
                      // value={email}
                      // onChange={(e) => {setEmail(e.target.value)}} 
                      placeholder='Password' />
              </Form.Group>
           </Form>
           <Form style={{marginTop:20}}>
            {['checkbox'].map((type) => (
                    <div key={`default-${type}`} className="mb-3">
                    <Form.Check 
                        type={type}
                        id={`default-${type}`}
                        label={'Remember me'}
                    />
                    </div>
                ))}
           </Form>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop:20}}>
              <Button  className='losg' style={{marginTop:20,cursor: 'pointer', backgroundColor:'white', width:'100%', color:'black'}}>Sign in</Button>
            </div>
                
            </div>
            </div> 
            </div>
            </Col>
       


            <Col className="col-2" xs={12} md={6}>
            <div className="col1div">
                 <div className='nsim' style={{marginTop:70, display: 'flex', justifyContent: 'center'}}>
                  <img className="logonsimg" src={logons} style={{width:550}}/>
                  </div> 
            </div>
            </Col>
        </Row>
    </div>
  )
}

export default Login