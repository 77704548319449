import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Header from '../src/Components/Header/Header'
import Router from '../src/Router'
import Footer from '../src/Components/Footer/Footer'
import {Helmet} from "react-helmet";


global.url = 'http://Website.nasscript.com/public/api/';
global.images = 'http://Website.nasscript.com/public/'

function App() {
  return (
    <div>
      <div>
      <Helmet>
            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        </Helmet>
      </div>      
      
      <Router />
     
    </div>
  );
}

export default App;
