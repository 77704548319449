import React, { useState, useCallback } from 'react';
import { Calendar } from '@natscale/react-calendar';
import './Blog.css'
import {Container, Row, Col, Form,Button, Modal} from "react-bootstrap"
import logo1 from '../../Assets/Images/Nlogo_black_s.svg';
import menu from '../../Assets/Images/Menuicon.svg';
import smimg from '../../Assets/Images/smimg.jpg';
import not1 from '../../Assets/Images/not1.jpg';
import not5 from '../../Assets/Images/not5.jpg';
import not6 from '../../Assets/Images/not6.jpg';
import not3 from '../../Assets/Images/not3.jpg';
import not7 from '../../Assets/Images/not7.jpg';
import {BiMessage} from 'react-icons/bi';
import {FcSettings, FcStumbleupon, FcServiceMark, FcElectronics} from 'react-icons/fc';
import {FaWhatsapp, FaInstagram, FaHandshake} from 'react-icons/fa';
import {TiSocialTwitterCircular, TiSocialLinkedinCircular} from 'react-icons/ti';
import {IoMdContact} from 'react-icons/io';
import {ImCross} from 'react-icons/im';
import imgin from '../../Assets/Images/new.jpg';
import logo11 from '../../Assets/Images/Nlogo_White_footage.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import {useHistory} from 'react-router-dom';
import shaik from "../../Assets/Images/shaik.jpeg"
import anas from "../../Assets/Images/anas.jpeg"
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';



function Blog() {


	const [value, setValue] = useState();
  
  const onChange = useCallback(
    (value) => {
      setValue(value);
    },
    [setValue],
  );
	   let history = useHistory();
       const [menu1, setMenu1] = useState(false);
	   const [show, setShow] = useState(false); 
	   const [display, setDisplay] = useState(false);
	   const [appointment, setAppointment] = useState(false);
	   const [feature, setFeature] = useState(false);
	   const [expert, setExpert] = useState(false);
	   const menuOpen = () =>{
			setMenu1(true)
	   }

	   const menuClose = () => {
		setMenu1(false)
	   }

	   const [login, setLogin] = useState(false);

	   const loginOpen= () =>{
		setLogin(true)
   }

   const [currency, setCurrency] = React.useState('EUR');



  return (
    <div>
		<div className="bimage">
		         <div className='blog'>
				<Container fluid>
					<Row>
						<div style={{display: 'flex', justifyContent:'space-between'}}>
							<div ><img src={logo1} style={{width:40}}/></div>
							<div onClick={menuOpen} ><img className='menucls' src={menu} style={{width:35}}/></div>
						</div>
						<div  onClick={() => history.push('contacts')} style={{backgroundColor:'black',height:50, width:50, display:'flex', padding:12, position:'fixed', marginTop:60, right:5, borderRadius: 100}}>
							<BiMessage size={25} style={{color:'white'}}/>
							{/* <div style={{color:'white', marginLeft:4}}>Contact Us</div> */}
						</div>
					</Row>
					<Row style={{position: 'relative'}}>
						<Col xs={12} md={8}>
						
							<div className="bname" >
							<div className="bwbfb" style={{fontSize:35, fontWeight: '200'}}>Finally a modern</div>
							<div>Website builder</div>
						    <div>for businesses</div>				
                            <div style={{marginLeft:20}}> <Button style={{backgroundColor: 'black', color: 'white', width:200}}>Create your Website</Button></div>
							</div>
							
						</Col>
					</Row>
					
				</Container>		
		  </div>
		
       		{menu1 ?
				<div className='menupage'>
					<Row>
					<Col xs={12} md={2}>
					<div style={{display: 'flex', justifyContent:'space-between'}}>
						<div ><img src={logo11} className='log' style={{width:40}} /></div>
						
					</div>

					<div style={{fontSize:35, marginTop:40, color: 'white',cursor: 'pointer'}}>Home</div>
					<div onClick={() => history.push('about')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>About</div>
					<div onClick={() => history.push('services')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>Services</div>
					<div onClick={() => history.push('contacts')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>Contact</div>
                    <div onClick={() => history.push('blog')} style={{fontSize:35, marginTop:30, color: 'red',cursor: 'pointer'}}>Blog</div>
					<div onClick={loginOpen} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}> Login</div>
					</Col>
					<Col xs={12} md={2}></Col>
					<Col xs={12} md={7}>
					<div style={{marginTop:80}}>
						<Row style={{positon:'relative'}}>
						<Col xs={12} md={4} lg={3}>
							<div><img src={anas}style={{width:150, height:150, borderRadius:80, marginTop:20}}/></div>
						</Col>
							<Col xs={12} md={8} lg={9}>
								<div  style={{fontSize:20, marginTop:30, color: 'white'}}>"You don't learn to walk by following rules.
								You learn by doing and falling over."</div>
								<div style={{fontSize:15, color: 'white', marginTop:5}}>Anas Athanikkal </div> 
							  <div style={{fontSize:14, color: 'white',  marginTop:5}}>CEO</div>
							</Col>
							
						</Row>
						<div style={{marginTop:50}}>
						<Row>
							<Col xs={12} md={8} lg={9}>
								<div  style={{fontSize:20, marginTop:30, color: 'white'}}>"You don't learn to walk by following rules.
								You learn by doing and falling over."</div>
								<div style={{fontSize:15, color: 'white', textAlign:'end'}}>Ibrahim Abdullah M H Al-Hail</div> 
							  <div style={{fontSize:14, color: 'white', textAlign:'end'}}>Chairman</div>
							</Col>
							<Col xs={12} md={4} lg={3} >
								<div><img src={shaik}style={{width:150, height:150, borderRadius:80, marginTop:20}}/></div>
							</Col>
						</Row>
						</div>
					</div>  
					</Col>
					<Col xs={12} md={1}>
					<div onClick={menuClose}>	
							<ImCross size={35} style={{color: 'white'}}/>
					</div>
					</Col>
					</Row>
				{login ?
					<div className='admcl'>
					<div onClick={() => history.push('login')} style={{cursor: 'pointer'}}>Admin Login</div>
					<div onClick={() => history.push('clogin')} style={{marginTop:10,cursor: 'pointer'}}>Client Login</div>
				</div>: null}
				</div>: null}
				
	</div>

            <div style={{height:790}}>
			<div className="bimage2">
			<Row style={{position: 'relative'}}>
						<Col xs={12} md={6}>
						
							<div className="bname1" >
							<div className="bclpd" style={{fontSize:35, fontWeight: '200', color: 'white'}}>Craft like a pro designer with</div>
							<div>The power of</div>
						    <div>building blocks</div>				
							</div>
							
						</Col>
						<Col xs={12} md={6}>
						
							<div className="bname2" >
							
								<img className='bimg2' src={imgin} />
										
							</div>
							
						</Col>
					</Row>
			</div>
			</div>

			 <div style={{height:790}}>
			<div className="bimage3">
			<Row style={{position: 'relative'}}>
						
						
							<div className="bname3" >
							<div>Enhance <span style={{fontSize:40, fontWeight: '400'}}>Your</span> Website</div>
						    <div><span style={{fontSize:40, fontWeight: '400'}}>With</span><span className="bshape">Shapes</span></div>				
							</div>
						
						
						<div className="bwhite">
							<Row>
								<Col xs={12} md={6}>							
                                  <img className="bsmimgn" src={smimg}/>
							    </Col>
								<Col xs={12} md={6}>							
                                  <div className="bpf" style={{fontSize:40, fontWeight: '400'}}>Property Trends</div>
								  <div style={{fontSize:16}}>white yruy uytyt gdyguydg</div>
								  <div style={{fontSize:16}}>ueiug gsdfjhf yruy uytyt gdyguydg</div>
								  <div style={{fontSize:16}}>zxfdg fggjg gykl uiuydg</div>
							    </Col>
							</Row>
						</div>
						</Row>	
					
			</div>
			</div>

		
		        {/* <div className='bglow'>
				<Container fluid>
					<Row>
						<div style={{fontSize:70, textAlign: 'center', fontWeight: 'bold'}}>Buisess Ready</div>
					</Row>
					
					<Row style={{position: 'relative'}}>
					    <Col xs={12} md={2}></Col>
						<Col xs={12} md={5}>
						<div style={{marginTop:40}}>
						<img src={imgin} />
						</div>
						</Col>
						<Col xs={12} md={5}>
						<div style={{marginTop:40}}>
						  <div style={{fontSize:25}}>Livechat</div>
						  <div style={{fontSize:18}}>Convert visitors into leads.</div>
						</div>
						<div style={{marginTop:10}}>
						  <div style={{fontSize:25}}>Built-in Customer portal</div>
						  <div style={{fontSize:18}}>Connect with your customers.</div>
						</div>
						<div style={{marginTop:10}}>
						  <div style={{fontSize:25}}>Multi-language ready</div>
						  <div style={{fontSize:18}}>Reach a larger audience.</div>
						</div>
						<div style={{marginTop:10}}>
						  <div style={{fontSize:25}}>Engineered for SEO</div>
						  <div style={{fontSize:18}}>We handle the magic for you!</div>
						</div>
						<div>
						<div style={{marginTop:10}}>
						  <div style={{fontSize:25}}>Based on a modern stack</div>
						  <div style={{fontSize:18}}>Python, PostgreSQL... and Open Source (LGPL).</div>
						</div>
							
							
							
						</div>
							
							
						</Col>
					</Row>
					
				</Container>		
				</div> */}
 
					<div style={{height:770, backgroundColor: 'yellow'}}>
						<div>
							
						<div className="im0">
						   <Row>							
                                  <img className="imim0" src={smimg}/>
								  <img className="imim1" src={not7}/>
								  <img className="imim2" src={not6}/>
								  <img className="imim3" src={not1}/>
								  <img className="imim4" src={not3}/>
								  <img className="imim5" src={not5}/>
							</Row>
						</div>
						<div className="im1">
						   <Row>							
                                  <img className="imim01" src={not1}/>
								  <img className="imim11" src={not3}/>
								  <img className="imim21" src={not5}/>
								  <img className="imim31" src={not7}/>
								  <img className="imim41" src={smimg}/>
								  <img className="imim51" src={not6}/>
							</Row>
						</div>
						<div className="im2">
						   <Row>							
                                  <img className="imim02" src={not5}/>
						
								  <img className="imim22" src={smimg}/>
								  <img className="imim32" src={not6}/>
								  <img className="imim42" src={not1}/>
								  <img className="imim52" src={not7}/>
							</Row>
						</div>
						
				
					
			</div>
			</div>
			
    </div>
  )
}

export default Blog