import React from 'react'
import './CLogin.css'
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import logo1 from '../../Assets/Images/Nlogo_black_s.svg';
import nastag from '../../Assets/Images/Nastag.svg';
import round from '../../Assets/Images/round.svg';
import {useHistory} from 'react-router-dom';


function CLogin() {

  let history = useHistory();

  return (
    <div className="mndiv" style={{height: 750, position: 'relative'}}>
       <div className="col1div1">
      <Row>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div ><img src={logo1} style={{width:40}}/></div> 
                      <div><img src={nastag} style={{width:150}}/></div>
                </div> 
      </Row>
      <Row>
        <div className="mbx">
        <div className='box-inside2'>
        <div style={{fontSize:25, fontWeight:600, color:'black', textAlign:'center'}}>Client Login</div>

        <div style={{marginTop:20}}>
        <Form>
        <Form.Group className="mb-1" controlId="formBasicEmail">
            
            <Form.Control 
                type="name"
                className='shadow12' 
                // value={email}
                // onChange={(e) => {setEmail(e.target.value)}} 
                placeholder='Username' />
        </Form.Group>
        </Form>
        </div>

        <div style={{marginTop:20}}>
        <Form>
        <Form.Group className="mb-1" controlId="formBasicEmail">
            
            <Form.Control 
                type="password"
                className='shadow12' 
                // value={email}
                // onChange={(e) => {setEmail(e.target.value)}} 
                placeholder='Password' />
        </Form.Group>
        </Form>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', marginTop:20}}>
        <Button  className='losg' style={{marginTop:20,cursor: 'pointer', backgroundColor:'white', width:'100%', color:'black'}}>Sign In</Button>
        </div>
        <div className='alrdy'>Already have account? &nbsp; <span onClick={() => history.push('signup')} style={{fontWeight:'bold'}}>Create one</span></div>
        </div>
        </div>
        
       
      </Row>
      <Row style={{position: 'relative'}}>
      <div className='rnd'>
            <img className='rndim' src={round}style={{width:250}}/>
          </div>
          </Row>
      </div>
     
</div>
  )
}

export default CLogin