import React from 'react'
import './Footer.css'
import {Container, Row, Col, Form} from "react-bootstrap"
import logo1 from '../../Assets/Images/Nlogo_black_s.svg';
import menu from '../../Assets/Images/Menuicon.svg';
import Gradient1 from '../../Assets/Images/Gradient1.svg';
import vector1 from '../../Assets/Images/Vector 1.svg';
import ellipse1 from '../../Assets/Images/Ellipse 1.svg';
import gradient2 from '../../Assets/Images/Gradient2.svg';
import client from '../../Assets/Images/Clients.svg';
import left from '../../Assets/Images/Client_Left arrow.svg';
import right from '../../Assets/Images/Client_Right arrow.svg';
import logo2 from '../../Assets/Images/Nlogo_White_footage.svg';
import {FiPhone} from 'react-icons/fi';
import {FaWhatsapp, FaInstagram} from 'react-icons/fa';
import {TiSocialTwitterCircular, TiSocialLinkedinCircular} from 'react-icons/ti';
import {RiFacebookCircleLine} from 'react-icons/ri';
import {BiCopyright} from 'react-icons/bi';
function Footer() {
  return (
    <div>
      <div className="black" style={{marginTop: -16}} >
	   
						<Row>
							<Col xs={12} md={9}>
								<div className="boot" style={{marginTop:50}}>
									<div className="stay" style={{color: 'white', fontSize: '50px', fontWeight: 700}}>Stay in Touch <span style={{color:'#32cd32', fontSize:'50px', fontWeight: 800}}>.</span></div>
									<div className='why why1' style={{color: 'white', fontSize: '40px'}}>NASScript Softwares Innovations W.L.L,</div>
								</div>
							</Col>
							<Col xs={12} md={3}>
								<div className='logo222' style={{marginTop: 45, display: 'flex', justifyContent: 'flex-end'}}>
                                <img className='logo22' src={logo2} style={{width: 100}} />
								</div>
							</Col>
						</Row>
						<div className='foot'>
							<Row>
								{/* <Col xs={12} md={5}>
									<div  style={{color:'white', marginTop:20, fontSize:15}}>
									<div className='why'>HEAD OFFICE</div>
									<div style={{marginTop:15, fontWeight:'medium'}}>India</div>
									<div className='why' style={{marginTop:5}}>NASScript Software Innovations Pvt Ltd,</div>
									<div className='why'>1/9H, Kootupatha, pattambi</div>
									<div className='why'>Palakkad,Kerala</div>
									<div className='why'>679533</div>
									<div style={{display:'flex'}}>
										<FiPhone size={18} style={{marginTop:17}}/>
										<div className='why' style={{marginTop:15, marginLeft:7}}>0466 2966969</div>
									</div>
									<div style={{display:'flex'}}>
										<FiPhone size={18} style={{marginTop:17}}/>
										<div className='why' style={{marginTop:15, marginLeft:7}}>+974 31602060, +97477716712</div>
									</div>
									<div style={{display:'flex'}}>
										<FaWhatsapp size={18} style={{marginTop:17}}/>
										<div className='why' style={{marginTop:15, marginLeft:7}}>+91 9745151015</div>
									</div>
									</div>
								</Col> */}
								<Col xs={12} md={6}>
									<div style={{color:'white', marginTop:20, fontSize:15}}>
									{/* <div className='why'>BRANCH OFFICE</div> */}
									<div style={{marginTop:15, fontWeight:'medium'}}>Qatar</div>
									<div className='why' style={{marginTop:5}}>NASScript Softwares Innovations W.L.L,</div>
									<div className='why'>Doha, Qatar</div>
									<div style={{display:'flex'}}>
										<FiPhone size={18} style={{marginTop:17}}/>
										<div className='why' style={{marginTop:15, marginLeft:7}}>+974 316002060</div>
									</div>
									</div>
								</Col>
								<Col xs={12} md={6}> 
									<div className='elements' style={{color:'white', marginTop:20, fontSize:15, float:'right'}}>
								
									<div className='why' style={{marginTop:15}}>Home</div>
									<div className='why' style={{marginTop:15}}>About</div>
									<div className='why' style={{marginTop:15}}>Services</div>
									<div className='why' style={{marginTop:15}}>Careers</div>
									<div className='why' style={{marginTop:15}}>Contact</div>
									
									</div>
																	
								</Col>
							</Row>
							<Row>
							<div style={{ marginTop:25,  display:'flex', justifyContent: 'center' }}>
										<FaInstagram size={20} style={{color:'white'}}/>
										<RiFacebookCircleLine size={22} style={{color:'white', marginLeft:8}}/>
										<TiSocialTwitterCircular size={23} style={{color:'white', marginLeft:8}}/>
										<TiSocialLinkedinCircular size={23} style={{color:'white', marginLeft:8}}/>
										</div>
							<div style={{color:'white', marginTop:30,marginBottom:50, fontSize:12, display: 'flex', justifyContent: 'center'}}>
										<div><BiCopyright size={13}  style={{marginRight:3, marginBottom:2}}/></div> 
										<div className='why'>2022 NASSCRIPT. All right reserved. Terms and condition. privacy policy</div>
									</div>
							</Row>
						</div>
						
					</div>
    </div>
  )
}

export default Footer
