import React from 'react'
import {BrowserRouter as Router, Switch} from 'react-router-dom'
import Home from '../src/Screens/Home/Home'
import More from '../src/Screens/Home/More'
import Product from '../src/Screens/Home/Product'
import About from './Components/About/About'
import Blog from './Components/Blog/Blog'
import Services from './Components/Services/Services'
import Contacts from './Components/Contacts/Contacts'
import Apply from './Components/Contacts/Apply'
import Developer from './Components/Contacts/Developer'
import Login from './Components/Login/Login'
import Signup from './Components/Signup/Signup'
import CLogin from './Components/Clinet-Login/CLogin'
import CommonLayout from './Components/Layout/CommonLayout'
function rout() {
  return (
    <div>
       <Router>
          <Switch>
              <CommonLayout exact path='/' component={Home} />
              <CommonLayout exact path='/more' component={More} />
              <CommonLayout exact path= '/about' component={About} />
              <CommonLayout exact path='/services' component={Services} />
              <CommonLayout exact path='/contacts' component={Contacts} />
              <CommonLayout exact path='/apply' component={Apply} />
              <CommonLayout exact path='/developer' component={Developer}/>
              <CommonLayout exact path='/product' component={Product}/>
              <CommonLayout exact path='/blog' component={Blog}/>
              <Login exact path='/login' component={Login} />
              <Signup exact path='/signup' component={Signup} />
              <CLogin exact path='/clogin' component={CLogin} />
          </Switch>
      </Router>
    </div>
  )
}

export default rout
