import React from 'react'
import './Home.css'
import sr from '../../Assets/Images/123.jpg'
import {useHistory} from 'react-router-dom';
import {GrNotes} from 'react-icons/gr'
import {IoIosContact} from 'react-icons/io'
import { RiDeleteBin5Line } from "react-icons/ri";
import {Container, Row, Col, Button, Form, Carousel} from "react-bootstrap"
import { ImMobile } from 'react-icons/im';
import {IoIosArrowDown} from 'react-icons/io';
import { FaSignInAlt, FaShoppingCart } from 'react-icons/fa';
import { IoCart } from 'react-icons/io'; 
import {RiAccountCircleFill } from 'react-icons/ri';
import Paper from '@mui/material/Paper';
import OutsideClickHandler from 'react-outside-click-handler';
function More() {

  const [show, setShow] =React.useState(false);
   let history = useHistory();


  return (
    <div className='more'>


<div className='head'>
      
          <Row>
            <Col xs={12} lg={9}>
            </Col>
            <Col xs={12} lg={3}>
            <div onClick={()=>setShow(true)} className='signitem'>
                    <div className='signitem1'>
                    <FaShoppingCart size={15} style={{marginBottom: 6}}/>
                    <a  style={{marginLeft:5}}>(5) ITEMS</a>
                    </div>      
                </div>  
            </Col>
          </Row>

      {show ?
      <div className='cart-div' style={{position: 'absalute'}}>
             <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            
               <div className='smdivision'>
                   <div className='cart' style={{fontSize:20, fontWeight:600}}>Cart</div>
                   <div className='onitm' style={{fontSize:11, color:'blue', fontWeight:600}}>(3 item)</div>
                   <hr></hr>

                   <div style={{display: 'flex', justifyContent: 'space-between'}}>
                     <div className='imgminplus'  style={{cursor: 'pointer'}}>
                        <img className='imfry' style={{borderRadius:30, width:45}}  src={sr} />
                        <div className='cfr' style={{fontSize:14, marginLeft:10, marginTop:7, width:115}} >dsgg</div>
                      </div>
                      <div style={{marginLeft:10, marginTop:7}}>45</div>
                        <div >
                          <p style={{fontSize: 14, textAlign: 'center', cursor: 'pointer', marginLeft:18, marginTop:7}}><RiDeleteBin5Line  color="#000" size={17} style={{marginTop: -4}} /> Remove</p>
                      </div>
                   </div>
                   <hr></hr>
           
                 <div style={{fontWeight:600, fontSize:18, marginTop:15}}>Bill Details</div>
                   <div style={{display:'flex', justifyContent:'space-between', marginTop:20}}>
                     <p>Item total</p>
                     <p>₹45</p>
                   </div>

                   <div style={{display:'flex', justifyContent:'space-between'}}>
                     <p>Delivery charges</p>
                     <p>₹30</p>
                   </div>

                   
                   <div style={{backgroundColor:'#f5f5f5',display:'flex', justifyContent:'space-between', padding:10}}>
                     <p style={{fontWeight:600, fontSize:18, marginTop:10, margin:'unset'}}>Total</p>
                     <p style={{fontWeight:600, fontSize:18, marginTop:10, margin:'unset'}}>₹30</p>
                   </div>

                   <div className='apply'>
                      <Button className='applybtn' style={{backgroundColor:'#7c003f', width:80, height:40}}>
                        <p  style={{fontSize:18, fontWeight:600}}>Apply</p>
                      </Button>
                   </div> 

                </div>
          
 
          </Paper> 
        
      </div>:null}

        <OutsideClickHandler  onOutsideClick={() =>{
               setShow(false);    
              }}>
        </OutsideClickHandler>
    </div>

      <div className='banner'>
        <Container>
          <Row>
              <Carousel>                                          
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={sr}
                          alt="fetch"
                          style={{width: '100%', height: 380, marginTop:100}}
                          />
                      </a>
                  </Carousel.Item>           
                                                    
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={sr}
                          alt="fetch"
                          style={{width: '100%', height: 380, marginTop:100}}
                          />
                      </a>
                  </Carousel.Item>           
                                                      
                  <Carousel.Item>
                        <a >
                          <img
                          className="d-block w-100 banner-img"
                          src={sr}
                          alt="fetch"
                          style={{width: '100%', height: 380, marginTop:100}}
                          />
                      </a>
                  </Carousel.Item>           
              </Carousel>
               
          </Row>
        </Container>
      </div>

      <div className='inmore'>
       <Container>
        <div className='alldiv'>
        <Row>
          <Col>
          <div className='web'>Website Apps</div>
                <Row>
                  <Col xs={12} md={3}>
                      <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                </Row>
                <div style={{marginTop: 10}}>
                <Row>
                <Col xs={12} md={3}>
                <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                </Row>
                </div>
          </Col>
        </Row>
        </div>
       </Container>
       </div>

       <div className='inmore1'>
       <Container>
        <div className='alldiv'>
        <Row>
          <Col>
          <div className='web1'>Sales Apps</div>
                <Row>
                <Col xs={12} md={3}>
                <div onClick={() => history.push('product')} className="pro1">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro1">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro1">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro1">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                </Row>
                <div style={{marginTop: 10}}>
                <Row>
                <Col xs={12} md={3}>
                <div onClick={() => history.push('product')} className="pro1">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro1">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                </Row>
                </div>
          </Col>
        </Row>
        </div>
       </Container>
       </div>


       <div className='inmore'>
       <Container>
        <div className='alldiv'>
        <Row>
          <Col>
          <div className='web'>Website Apps</div>
                <Row>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                </Row>
                <div style={{marginTop: 10}}>
                <Row>
                <Col xs={12} md={3}>
                <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                  <Col xs={12} md={3}>
                  <div onClick={() => history.push('product')} className="pro">
                        <div >
                          <IoIosContact size={40}/>
                          </div>
                        <div style={{fontSize:16, marginTop:2}}>iyuiyuytuy</div>
                        <div style={{height:0}}>
                        <ul className="list-inline">
                             <li><Button variant="outlined"><div style={{fontSize:12}}>Add cart</div></Button></li>
                        </ul>
                        </div>
                      </div>
                  </Col>
                </Row>
                </div>
          </Col>
        </Row>
        </div>

        

        <div style={{marginBottom:40}}>
        <Row>
            <Col xs={12} md={6}>
               
                  <img className='meta' src={sr} /> 
            </Col>
            <Col xs={12} md={6}>
               
                  <img className='meta' src={sr} /> 
            </Col>    
        </Row>
        </div>
       </Container>
       </div>



    </div>
  )
}

export default More