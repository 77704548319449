import React from 'react'
import './Signup.css'
import {Container, Row, Col, Form, Button} from "react-bootstrap"
import logo1 from '../../Assets/Images/Nlogo_black_s.svg';
import nstag1 from '../../Assets/Images/Nastag1.svg';
import nastag from '../../Assets/Images/Nastag.svg';
import {useHistory} from 'react-router-dom';

function Signup() {

    let history = useHistory();

  return (
    <div  style={{height: 760, position: 'relative'}}>
    {/* <Row>
        <div style={{width: '50%',height: 760}}>gdfh</div>
        <div style={{width: '50%', backgroundColor:'black',height: 760}}>dfgd</div>
    </Row> */}
    <Row>
        <Col className="col-1" xs={12} md={6}>
        <div className="col1div1">
        <div className='logmndiv' style={{display: 'flex', justifyContent:'space-between'}}>
                                <div ><img src={logo1} style={{width:40}}/></div>
                                
                </div> 
                <div className='logmndiv1' style={{display: 'flex', justifyContent:'space-between'}}>
                                <div ><img src={logo1} style={{width:40}}/></div>
                                <div><img src={nastag} style={{width:150}}/></div>
                                
                </div>  
                <div className="bxinsdiv">
            <div className="lbx">   
        <div className='box-inside1'>
        <div style={{fontSize:25, fontWeight:600, color:'black', textAlign:'center'}}>Create Account</div>
        
        <div style={{marginTop:20, display: 'flex'}}>
         <div style={{marginTop:20}}>
            <Form>
            <Form.Group className="mb-1" controlId="formBasicEmail">
                <Form.Control 
                    type="email"
                    className='shadow12' 
                    // value={email}
                    // onChange={(e) => {setEmail(e.target.value)}} 
                    placeholder='First name' />
            </Form.Group>  
        </Form>
       </div>
       <div style={{marginLeft:10, marginTop:20}}>
            <Form>
            <Form.Group className="mb-1" controlId="formBasicEmail">
                <Form.Control 
                    type="email"
                    className='shadow12' 
                    // value={email}
                    // onChange={(e) => {setEmail(e.target.value)}} 
                    placeholder='Last name' />
            </Form.Group>  
        </Form>
       </div>
        </div>

        <div style={{marginTop:20}}>
        <Form>
          <Form.Group className="mb-1" controlId="formBasicEmail">
             
              <Form.Control 
                  type="email"
                  className='shadow12' 
                  // value={email}
                  // onChange={(e) => {setEmail(e.target.value)}} 
                  placeholder='Mobile' />
          </Form.Group>
       </Form>
        </div>

        <div style={{marginTop:20}}>
        <Form>
          <Form.Group className="mb-1" controlId="formBasicEmail">
             
              <Form.Control 
                  type="email"
                  className='shadow12' 
                  // value={email}
                  // onChange={(e) => {setEmail(e.target.value)}} 
                  placeholder='E-mail' />
          </Form.Group>
       </Form>
       
        </div>
        
        <div style={{marginTop:20}}>
        <Form>
          <Form.Group className="mb-1" controlId="formBasicEmail">
             
              <Form.Control 
                  type="password"
                  className='shadow12' 
                  // value={email}
                  // onChange={(e) => {setEmail(e.target.value)}} 
                  placeholder='Project Code' />
          </Form.Group>
       </Form>
        </div>
        <div style={{display: 'flex', justifyContent: 'center', marginTop:20}}>
          <Button  className='losg' style={{marginTop:20,cursor: 'pointer', backgroundColor:'white', width:'100%', color:'black'}}>Sign up</Button>
        </div>
        </div>
        <div style={{marginTop:20, textAlign: 'center'}}>Already have account? &nbsp; <span onClick={() => history.push('clogin')} style={{fontWeight:'bold'}}>Login</span></div>
         </div>  
         </div>
         </div>
        </Col>
   
        <Col className="col-2" xs={12} md={6}>
            <div className="col1div1">
                 <div style={{display: 'flex', justifyContent: 'flex-end'}}><img src={nstag1} style={{width:150}}/></div> 
            </div>
        </Col>
    </Row>
</div>
  )
}

export default Signup