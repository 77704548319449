import React, {useState, useCallback} from 'react'
import './Home.css'
import {Container, Row, Col, Form, Modal} from "react-bootstrap"
import { Calendar } from '@natscale/react-calendar';
import demo from '../../Assets/Images/123.jpg'
import demo1 from '../../Assets/Images/345.jpg'
import {BsDot} from 'react-icons/bs'
import menu from '../../Assets/Images/menu icons.svg';
import Gradient1 from '../../Assets/Images/Gradient red.svg';
import logo1 from '../../Assets/Images/Nlogo_White_footage.svg';
import line from '../../Assets/Images/Line Design.svg';
import ellipse from '../../Assets/Images/Ellipse1.svg';
import lineor from '../../Assets/Images/lineor.svg';
import cra from '../../Assets/Images/Client_Right arrow.svg';
import img1 from '../../Assets/Images/Image1m.svg';
import img1sh from '../../Assets/Images/Image1msh.svg';
import img2 from '../../Assets/Images/Image2.svg';
import img2sh from '../../Assets/Images/Image2sh.svg';
import recg from '../../Assets/Images/recgreen.svg';
import devimg from '../../Assets/Images/App development image.svg';
import cliri from '../../Assets/Images/Client_Right arrow.svg';
import clile from '../../Assets/Images/Client_Left arrow.svg';
import recb from '../../Assets/Images/recblue.svg';
import ecom from '../../Assets/Images/E commerce plat  image.svg';
import reco from '../../Assets/Images/recorange.svg';
import esof from '../../Assets/Images/Enterprise Software image.svg';
import recy from '../../Assets/Images/recyellow.svg';
import brand from '../../Assets/Images/Branding image.svg';
import logo11 from '../../Assets/Images/Nlogo_White_footage.svg';
import {ImCross} from 'react-icons/im';
import {useHistory} from 'react-router-dom';
import shaik from "../../Assets/Images/shaik.jpeg"
import anas from "../../Assets/Images/anas.jpeg"
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import {IoMdContact} from 'react-icons/io';
import { motion } from "framer-motion";


const currencies = [
	{
	  value: 'USD',
	  label: 'India',
	},
	{
	  value: 'EUR',
	  label: 'Qatar',
	},
  ];
  const Language = [
	{
	  value: 'USD',
	  label: 'Malayalam',
	},
	{
	  value: 'EUR',
	  label: 'English',
	},
  ];
  const Company = [
	{
	  value: 'USD',
	  label: 'Malayalam',
	},
	{
	  value: 'EUR',
	  label: 'Nas',
	},
  ];
  const primary = [
	{
	  value: 'USD',
	  label: 'Malayalam',
	},
	{
	  value: 'EUR',
	  label: 'Development',
	},
  ];

function Product() {

  const [isActive, setIsActive] = React.useState(false);
  const [value, setValue] = useState();
  
  const onChange = useCallback(
    (value) => {
      setValue(value);
    },
    [setValue],
  );

  
	   const [show, setShow] = useState(false); 
	   const [display, setDisplay] = useState(false);
	   const [appointment, setAppointment] = useState(false);
	   const [feature, setFeature] = useState(false);
	   const [expert, setExpert] = useState(false);
	  

	   

   const [currency, setCurrency] = React.useState('EUR');

  let history = useHistory();
	const [menu1, setMenu1] = useState(false);

	const menuOpen = () =>{
		 setMenu1(true)
	}

	const menuClose = () => {
	 setMenu1(false)
	}

	const [login, setLogin] = useState(false);

	const loginOpen= () =>{
	 setLogin(true)
}


  return (
    <div>
        <div className='serv'>
            <Container fluid>
              <Row style={{position: 'relative'}}>
              <Col xs={12} md={6}>
                <div style={{position: 'relative'}}>
                  <img src={cra} className='crad' />
                </div>
                <div className='webu' style={{fontSize:42, color: 'white', fontWeight:'550', marginTop:150}}>
                The real customer centric CRM
                </div>
                <p className='paraf' style={{fontSize:18, color: 'white', fontWeight:300, marginTop: 20, marginBottom:50}}>
                    An application that allows users to connect all their social profiles, all their social profiles
                </p>
                    <div onClick={()=>setShow(true)} style={{textAlign: 'center'}}>
                    <Button style={{backgroundColor: 'white', color: 'black'}}>Starts Now, it's free</Button>
                    </div>
            </Col> 
            <Col xs={12} md={6}>
              <img src={img1} className='img1' style={{width:'45%'}}/>
              <img src={img1sh} className='img1sh'  style={{width:700}}/>
              
            </Col>
              </Row>
              
            </Container>
        </div>

        <div className='about3'>
        <Row>
          <Col xs={12} md={6}>
              <div className='webi' style={{fontSize:42, fontWeight:'550', marginTop:20}}>
              Get accurate forecasts Use actionable data to make better decisions.
              </div>
              <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
              Use actionable data to make better decisions.
              </p>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00', marginBottom:3}} />
                <div style={{marginTop: 2}}>Get the insights you need to make smarter decisions.</div>
                </div>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2}}>CDesign custom of your business at a glance.</div>
                </div>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2}}>Test dashboards to get a picture Driven</div>
                </div>
          </Col>  
          <Col className='col'  style={{position: 'relative'}} xs={12} md={6}>
              <img src={img2} className='img2' style={{width:'100%'}}/>
              <img src={img2sh} className='img2sh'/>      
          </Col>  
        </Row>
      </div>
     
      <div className='about4'>
        <Row>
        <Col className='col'  style={{position: 'relative'}} xs={12} md={6}>
              <img src={img2} className='img2' style={{width:'100%'}}/>
              <img src={img2sh} className='img2sh'/>      
          </Col> 
          <Col onClick={() => setIsActive(!isActive)} xs={12} md={6}>
              <div className='webi' style={{fontSize:42, fontWeight:'550', marginTop:20, color:'white'}}>
              Get accurate forecasts Use actionable data to make better decisions.
              </div>
              <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20, color:'white'}}>
              Use actionable data to make better decisions.
              </p>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00', marginBottom:3}} />
                <div style={{marginTop: 2, color:'white'}}>Get the insights you need to make smarter decisions.</div>
                </div>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2, color:'white'}}>CDesign custom of your business at a glance.</div>
                </div>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2, color:'white'}}>Test dashboards to get a picture Driven</div>
                </div>
          </Col>   
        </Row>
      </div>

	  <motion.div
      className="block"
      onClick={() => setIsActive(!isActive)}
      animate={{
        rotate: isActive ? 180 : 360
      }}
    >
      Hello Framer motion
    </motion.div>
     
      <div className='about3'>
        <Row>
          <Col xs={12} md={6}>
              <div className='webi' style={{fontSize:42, fontWeight:'550', marginTop:20}}>
              Get accurate forecasts Use actionable data to make better decisions.
              </div>
              <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20}}>
              Use actionable data to make better decisions.
              </p>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00', marginBottom:3}} />
                <div style={{marginTop: 2}}>Get the insights you need to make smarter decisions.</div>
                </div>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2}}>CDesign custom of your business at a glance.</div>
                </div>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2}}>Test dashboards to get a picture Driven</div>
                </div>
          </Col>  
          <Col className='col'  style={{position: 'relative'}} xs={12} md={6}>
              <img src={img2} className='img2' style={{width:'100%'}}/>
              <img src={img2sh} className='img2sh'/>      
          </Col>  
        </Row>
      </div>

      <Modal show={show} onHide={() => setShow(false)}>
					<Modal.Header closeButton>
					<Modal.Title><h1>CONTENT HERE</h1></Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<div>
					
							<h3>Content here</h3>
							
					
						<div className='hrhr'></div>
						<div style={{ fontSize:14, color: 'rgb(155 155 155)'}}>
							xxxxxx xxxxxxx xxxxxx xxxxxxxxxxx xxxxxx xxxx<br />
							xxxxx xxxxxxx
						</div>
					
						<hr></hr>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div onClick={() => setExpert(true)}><Button style={{backgroundColor: 'black'}}> meet an expert</Button></div>
						<div onClick={()=>setAppointment(true)}><Button style={{backgroundColor: 'black'}}> get Appointment</Button></div>
						<div><Button style={{backgroundColor: 'black'}}> watch video</Button></div>
						
						</div>
						<div style={{display: 'flex',marginTop:20}}>
						<div  onClick={() => setFeature(true)}><Button style={{backgroundColor: 'black'}}> download feature</Button></div>
						<div onClick={()=>setDisplay(true)} style={{marginLeft:25}}><Button style={{backgroundColor: 'black'}}> book demo</Button></div>
						</div>
						
					</div>
					</Modal.Body>
					<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Close
					</Button>
					</Modal.Footer>
				</Modal>



				<Modal show={display} onHide={() => setDisplay(false)}>
					<Modal.Header closeButton>
					<Modal.Title><h1>Nasscript CRM</h1></Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<div>
					
							<h4>instantaccess</h4>
							<div>
								<Row>
									<Col xs={12}>
									<TextField style={{width: '100%'}} id="standard-basic" label="First and Last name" variant="standard" />
									</Col>
								</Row>
								<Row >
									<Col xs={12}>
									<TextField style={{width: '100%'}} id="standard-basic" label="Email" variant="standard" />
									</Col>
								</Row>
								<Row>
									<Col xs={12} md={6}>
									<TextField style={{width: '100%'}} id="standard-basic" label="Company Name" variant="standard" />
									</Col>
									<Col xs={12}  md={6}>
									<TextField style={{width: '100%'}} id="standard-basic" label="Phone No" variant="standard" />
									</Col>
								</Row>
								<Row>
									<Col xs={12} md={6}>
									<TextField style={{width: '100%'}}
										id="standard-select-currency"
										select
										label="Country"
										value={currency}
										variant="standard"
										>
										{currencies.map((option) => (
											<MenuItem key={option.value} value={option.value}>
											{option.label}
											</MenuItem>
										))}
									</TextField>
									</Col>
									<Col xs={12}  md={6}>
									<TextField style={{width: '100%'}}
										id="standard-select-currency"
										select
										label="Language"
										value={currency}
										variant="standard"
										>
										{Language.map((option) => (
											<MenuItem key={option.value} value={option.value}>
											{option.label}
											</MenuItem>
										))}
									</TextField>
									</Col>
								</Row>
								<Row >
									<Col xs={12} md={6}>
									<TextField style={{width: '100%'}}
										id="standard-select-currency"
										select
										label="Company Size"
										value={currency}
										variant="standard"
										>
										{Company.map((option) => (
											<MenuItem key={option.value} value={option.value}>
											{option.label}
											</MenuItem>
										))}
									</TextField>
									</Col>
									<Col xs={12}  md={6}>
									<TextField style={{width: '100%'}}
										id="standard-select-currency"
										select
										label="primary intrest"
										value={currency}
										variant="standard"
										>
										{primary.map((option) => (
											<MenuItem key={option.value} value={option.value}>
											{option.label}
											</MenuItem>
										))}
									</TextField>
									</Col>
								</Row>
								<div style={{backgroundColor: "#dee2e6", padding: 15, marginTop:25}}>
									<div style={{fontSize:11}}>By clicking on <span style={{fontWeight:'bold'}}>START NOW</span> ,you accept our subscription agreement and privacy policy</div>
								</div>
							</div>
					</div>
					</Modal.Body>
					<Modal.Footer>
		
					<Button style={{color:'white', backgroundColor:'black'}} onClick={() => setShow(false)}>START NOW </Button>
					
					
					</Modal.Footer>
				</Modal>




			<Modal  size="lg" show={appointment} onHide={() => setAppointment(false)}>
				<Modal.Header closeButton>
				<Modal.Title>
					<Row style={{marginTop: 20}}>
						<Col xs={12} md={9}>
							<div style={{backgroundColor: "#dee2e6", padding: 15, marginTop:25, textAlign: "center"}}>
							<h4>I want a demo with an Nasscript expert - DS</h4>
							</div>
						</Col>
						
                    <Col xs={12}  md={3}>
                        <ul className="list-inline" style={{display:'flex', marginTop: 40}}>
                            <li><Button variant="outlined">time</Button></li>
                            <li><Button variant="outlined">confirmation</Button></li> 
                        </ul>
                    </Col>
                </Row>		
					
				</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				   <Row>
					<Col xs={12}  md={2}>
				       <div style={{backgroundColor: "#dee2e6", display:'flex', padding: 1, textAlign: "center"}}>
							<div style={{marginLeft: 16}}>Duration</div>	
					   </div>
					</Col>
					<Col xs={12}  md={2}>
				       <div style={{backgroundColor: "#dee2e6", display:'flex', padding: 1,}}>
							<div style={{marginLeft: 10}}>01:00 hour</div>	
					   </div>
					</Col>
					<Col xs={12}  md={2}></Col>
					<Col xs={12}  md={2}>
				       <div style={{backgroundColor: "#dee2e6", display:'flex', padding: 1, textAlign: "center"}}>
							<div style={{marginLeft: 16}}>Location</div>	
					   </div>
					</Col>
					<Col xs={12}  md={2}>
				       <div style={{backgroundColor: "#dee2e6", display:'flex', padding: 1,}}>
							<div style={{marginLeft: 16}}>Online</div>	
					   </div>
					</Col>
				   </Row>
				   <Row >
					<div style={{marginTop: 30}}>Schedule a 1 houre free meeting with an expert, to get:</div>
				   </Row>
				   <Row >
					<div style={{marginTop: 20}}>
						<ol>
							<li>a tallored demonstration</li>
							<li>recommendations based on your needs</li>
							<li>answers to your questions about Nas</li>
							<li>information about pricing & methodology</li>
						</ol>
					</div>
				   </Row>
				   <div style={{marginTop: 20}}>
				   <Row style={{marginTop: 20}}>
                      <Col xs={12} md={7}>
					  <div  >
					<Calendar value={value} onChange={onChange} />
					</div>
					  </Col>
					  <Col xs={12} md={4}>
					  <div>
					<div style={{textAlign: 'center', marginBottom:30}}>Tuesday 25 october</div>
					<div>
					<Row>
					<Col xs={12} md={6}>
							<div style={{border: '1px solid black'}}>
							<div style={{marginLeft: 16}}>8:00 AM</div>
							</div>
						</Col>
						<Col xs={12} md={6}>
							<div style={{border: '1px solid black'}}>
							<div style={{marginLeft: 16}}>9:00 AM</div>
							</div>
						</Col>
					</Row>
					</div>
					<div style={{marginTop: 20}}>
					<Row>
					<Col xs={12} md={6}>
							<div style={{border: '1px solid black'}}>
							<div style={{marginLeft: 16}}>10:00 AM</div>
							</div>
						</Col>
						<Col xs={12} md={6}>
							<div style={{border: '1px solid black'}}>
							<div style={{marginLeft: 16}}>12:00 PM</div>
							</div>
						</Col>
					</Row>
					</div>
					<div style={{marginTop: 20}}>
					<Row>
					<Col xs={12} md={6}>
							<div style={{border: '1px solid black'}}>
							<div style={{marginLeft: 16}}>1:00 PM</div>
							</div>
						</Col>
						<Col xs={12} md={6}>
							<div style={{border: '1px solid black'}}>
							<div style={{marginLeft: 16}}>2:00 PM</div>
							</div>
						</Col>
					</Row>
					</div>
					<div style={{marginTop: 20}}>
					<Row>
					<Col xs={12} md={6}>
							<div style={{border: '1px solid black'}}>
							<div style={{marginLeft: 16}}>3:00 PM</div>
							</div>
						</Col>
						<Col xs={12} md={6}>
							<div style={{border: '1px solid black'}}>
							<div style={{marginLeft: 16}}>4:00 PM</div>
							</div>
						</Col>
					</Row>
					</div>
					
					 </div>
					  </Col>
				   </Row>
				   </div>
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={() => setAppointment(false)}>
					Close
				</Button>
				</Modal.Footer>
			</Modal>



			<Modal  show={feature} onHide={() => setFeature(false)}>
				<Modal.Header closeButton>
				<Modal.Title>		
					<h3>Features</h3>
				</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				  <div>
					<h4>Heading</h4>
					<div>Details</div>
					<div>Details</div>
				  </div>
				  <div>
					<h4>Heading</h4>
					<div>Details</div>
					<div>Details</div>
				  </div>
				  <div>
					<h4>Heading</h4>
					<div>Details</div>
					<div>Details</div>
				  </div>
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={() => setFeature(false)}>
					Close
				</Button>
				</Modal.Footer>
			</Modal>

			<Modal  show={expert} onHide={() => setExpert(false)}>
				<Modal.Header closeButton>
				<Modal.Title>		
					<h3>Experts</h3>
				</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				  <div>
					<div><IoMdContact size={30}/></div>
                    <div>fdgh rdtyhfj</div>
				  </div>

				  <div style={{marginTop: 20}}>
					<div><IoMdContact size={30}/></div>
                    <div>fdgh rdtyhfj</div>
				  </div>

				  <div style={{marginTop: 20}}>
					<div><IoMdContact size={30}/></div>
                    <div>fdgh rdtyhfj</div>
				  </div>
				  
				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={() => setExpert(false)}>
					Close
				</Button>
				</Modal.Footer>
			</Modal>

      {/* <div className='picdiv'>
        <Row >
            <Col className='colcol' style={{position: 'relative'}} xs={12} md={1} lg={1}>
               <img className='recg' src={recg} style={{height:'60%'}}/>
            </Col>
            <Col style={{position: 'relative'}} xs={12} md={6} lg={4}>
               <div className='num'>01</div>
               <div className='appnm'>Application</div>
               <div className='depnm'>Development</div>
               <div className='praf' style={{fontSize:18, fontWeight:300}}>Our expert in-house teams are dedicated for
                developing mobile apps to faster your business.
                Our entire work is the result of continous
                research, unceasing creativity and scrupulous execuition
               </div> 
               <div className='wwdar'>
                <div className='wtwdo' style={{fontSize:20}}>What we do?</div>
                <img className="cliri" src={cliri} style={{width:150,marginLeft:210}} />
               </div>  
            </Col>
            <Col xs={12} md={5} lg={7}>
               <img className='devimg' src={devimg} style={{width:'100%'}}/>
            </Col>
        </Row>
       
      </div>

      <div className='picdiv1'>
        <Row >
            <Col className='colimg' xs={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }} lg={{ span: 6, order: 1 }}>
               <img className='devimg1' src={ecom} style={{width:'100%'}}/>
            </Col>
            <Col style={{position: 'relative'}} xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 5, order: 2 }}>
               <div className='num2'>02</div>
               <div className='sing'>Single & Multi seller</div>
               <div className='plat'>E-Commerce Platform</div>
               <div className='paraexp' style={{fontSize:18, fontWeight:300}}>
                Expand business via multifarious digital channels
                all around the world.Our teams of experts have
                in-depth knowledge in various e-commerce web
                development platforms which will give a new look
                to your business
               </div> 
               <div className='wwdal'>
                <img className="clile" src={clile} style={{width:150,marginRight:310}} />
                <div className='wtwedo' style={{fontSize:20}}>What we do?</div>
               </div>
            </Col>
            <Col className='colcol2' style={{position: 'relative'}} md={{ span: 1, order: 3 }} lg={{ span: 1, order: 3 }}>
               <img className='recb' src={recb} style={{height:'60%'}}/>           
            </Col>
        </Row> 
      </div>

      <div className='picdiv'>
        <Row >
            <Col className='colcol' style={{position: 'relative'}} xs={12} md={1} lg={1}>
               <img className='recg' src={reco} style={{height:'60%'}}/>
            </Col>
            <Col style={{position: 'relative'}} xs={12} md={6} lg={4}>
               <div className='num'>03</div>
               <div className='appnm'>Enterprise</div>
               <div className='depnm'>Software</div>
               <div className='smdiv1'>
               <div className='smdiv2' style={{display: 'flex', fontSize:14}}>
                <BsDot size={30} style={{color:'#ff7a00', marginBottom:3}} />
                <div style={{marginTop: 2}}>Agile Approach</div>
                </div>
                <div className='smdiv2' style={{display: 'flex', fontSize:14}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2}}>Continous delivery</div>
                </div>
                <div className='smdiv2' style={{display: 'flex', fontSize:14}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2}}>Test Driven</div>
                </div>
               </div>
               <div className='praf' style={{fontSize:18, fontWeight:300}}>
                With Cavernous prowess in full-cycle corporate
                software development, NASSCRIPT improves and 
                automates business with unmatched enterprise 
                applications characterized by 3 specific 
                attributes
               </div> 
               <div className='wwdar'>
                <div className='wtwdo' style={{fontSize:20}}>What we do?</div>
                <img className="cliri" src={cliri} style={{width:150,marginLeft:210}} />
               </div>  
            </Col>
            <Col className="colimg" xs={12} md={5} lg={7}>
               <img className='devimg1' src={esof} style={{width:'100%'}}/>
            </Col>
        </Row>
       
      </div>

      <div className='picdiv1'>
        <Row >
            <Col className='colimg' xs={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }} lg={{ span: 6, order: 1 }}>
               <img className='devimg2' src={brand} style={{width:'100%', marginTop:100}}/>
            </Col>
            <Col style={{position: 'relative'}} xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 5, order: 2 }}>
               <div className='num2'>04</div>
               <div className='sing'>Branding</div>
               <div className='paraexp' style={{fontSize:18, fontWeight:300}}>
               Business can attempt to shape or form the 
               branding of their company or products in many
               ways.A lot of thoughts and efforts goes into
               branding including, naming products, designing 
               logos and ensuring that service is uniform
               throughout the business.
               </div> 
               <div className='wwdal'>
                <img className="clile" src={clile} style={{width:150,marginRight:310}} />
                <div className='wtwedo' style={{fontSize:20}}>What we do?</div>
               </div>
            </Col>
            <Col className='colcol2' style={{position: 'relative'}} md={{ span: 1, order: 3 }} lg={{ span: 1, order: 3 }}>
               <img className='recb' src={recy} style={{height:'60%'}}/>           
            </Col>
        </Row> 
      </div> */}

    </div>
  )
}

export default Product