import React, {useState} from 'react'
import './Contacts.css'
import {useHistory} from 'react-router-dom';
import {Container, Row, Col, Button, Form, Modal} from "react-bootstrap"
import {GrNotes} from 'react-icons/gr'
import {IoIosContact} from 'react-icons/io'
import TextField from '@mui/material/TextField';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function Developer() {

    let history = useHistory();
    const [display, setDisplay] = useState(false);

  return (
    <div className='apply'>
        <Container>
            <Row>
                <div className="search">React Developer</div>
            </Row>
            <Row>
                <Col xs={12} md={3}></Col>
                <Col xs={12} md={6}>
                <div className="pros1">
                    <Row>
                        <div style={{fontSize:20, color: 'white', textAlign: 'center'}}>Job Code: NSI-NZMCZ</div>
                    </Row>
                        <div style={{marginTop:15}}>
                           <Row>
                             <Col xs={12} md={6}>
                                <div style={{color: 'white'}}>Experiene: 1 Year</div>
                                <div style={{color: 'white'}}>Location: Kootupatha, Pattambi, India</div>
                             </Col>
                             <Col xs={12} md={6}>
                                <div style={{color: 'white'}}>Vacancy: 1</div>
                                <div style={{color: 'white'}}>Publish Date: 28 Nov 2020</div>
                             </Col>
                           </Row> 
                        </div>
                        <div style={{marginTop:15}}>
                            <Row>
                                <div onClick={()=>setDisplay(true)} style={{marginTop:30, textAlign:'center'}}>
                                    <Button style={{backgroundColor: 'white', color: 'black'}}>Apply</Button>
                                </div>
                            </Row>
                        </div>
                </div>
                </Col>
                <Col xs={12} md={3}></Col>
                <Col xs={12} md={4}></Col>
            </Row>
<Row>
    <div style={{marginTop:25}}>
        <div style={{fontSize:20, fontWeight:550, marginTop:20}}>Eligibility Criteria:</div>
        <div style={{marginTop:10}}>REACT Natie knowledge with a minimum of 2 years of experience. Knowledge in Android Native Development will be an added advantage. Also, we prefer if he/she has an added knowledge in iOS as well.</div>
    </div>
</Row>
<Row>
    <div style={{marginTop:25}}>
        <div style={{fontSize:20, fontWeight:550}}>Responsibilities and Duties</div>
        <div style={{marginTop:10}}>
            <ul className="ul">
                 <li>Minimum 2 years of experience in developing Android applications is a must.</li>
                 <li>Strong knowledge of Android UI design principles, patterns, and best practices.</li>
                 <li>Knowledge of Android SDK versions and compatibility standards.</li>
                 <li>Good knowledge in Web service/API integration.</li>
                 <li>Ability to understand requirements and convert them into technical requirements.</li>
                 <li>Experience using source code version control systems (Git/GitLab)</li>
            </ul>
        </div>
    </div>
</Row>
<Row>
    <div style={{marginTop:25}}>
        <div style={{fontSize:20, fontWeight:550}}>About NASScript:</div>
        <p style={{marginTop:10}}>
        NASScript is a software development company offering a wide range of services worldwide. 
        Our main objective is to furnish high-quality fast services at a low cost. We planned to start this company during the year 2015 by collecting details and funds to make this a new venture. 
        After all, it was launched in 2020 under the name 'NAS International Group of Company'. The company officially registered its trademark as 'NASScript' and starts working.
        </p>
    </div>
</Row>      
<Row>
    <div onClick={()=>setDisplay(true)} style={{marginTop:25}}>
    <Button style={{backgroundColor: 'black', color: 'white'}}>Apply</Button>
    </div>
</Row>        
        </Container>

        <Modal show={display} onHide={() => setDisplay(false)}>
					<Modal.Header closeButton>
					<Modal.Title><h4>React Developer</h4></Modal.Title>
					</Modal.Header>
					<Modal.Body>
					<div>
							<div>
								<Row>
									<Col xs={12}>
									<Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="Full Name" />
                                        </Form.Group>
                                    </Form>
									</Col>
								</Row>
								<Row >
									<Col xs={12}>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="email" placeholder="Email" />
                                        </Form.Group>
                                    </Form>
									</Col>
								</Row>
                                <Row>
									<Col xs={12}>
									<Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="Contact Number" />
                                        </Form.Group>
                                    </Form>
									</Col>
								</Row>
                                <Row>
									<Col xs={12}>
									<Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="Relevent years Of Work Experience" />
                                        </Form.Group>
                                    </Form>
									</Col>
								</Row>
                                <Row>
									<Col xs={12}>
									<Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="Current Salary (optinal)" />
                                        </Form.Group>
                                    </Form>
									</Col>
								</Row>
                                <Row>
									<Col xs={12}>
									<Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="Expected Salary" />
                                        </Form.Group>
                                    </Form>
									</Col>
								</Row>
                                <Row>
									<Col xs={12}>
									<Form>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" placeholder="Current Location" />
                                        </Form.Group>
                                    </Form>
									</Col>
								</Row>
								
								<Row>
                                    <div>Upload Resume (Below 1 MB)</div>
                                    <div style={{display: "flex", marginTop:15}}>
                                    <div style={{marginLeft: 20}}>
                                        <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="download-table-xls-button"
                                        table="table-to-xls"
                                        filename="tablexls"
                                        sheet="tablexls"
                                        buttonText="Choose File"/>
                                    </div>
                                    <div style={{marginLeft:5}}>No File Choosen</div>
                                    </div>
                                </Row>
						
							</div>
					</div>
					</Modal.Body>
					<Modal.Footer>
					
					
					<Button style={{color:'white', backgroundColor:'black'}} >Apply Now</Button>
					
					
					</Modal.Footer>
				</Modal>
    </div>
  )
}

export default Developer