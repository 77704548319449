import React, {useState} from 'react'
import './Services.css'
import {Container, Row, Col, Form} from "react-bootstrap"
import demo from '../../Assets/Images/123.jpg'
import demo1 from '../../Assets/Images/345.jpg'
import {BsDot} from 'react-icons/bs'
import menu from '../../Assets/Images/menu icons.svg';
import Gradient1 from '../../Assets/Images/Gradient red.svg';
import logo1 from '../../Assets/Images/Nlogo_White_footage.svg';
import line from '../../Assets/Images/Line Design.svg';
import ellipse from '../../Assets/Images/Ellipse1.svg';
import lineor from '../../Assets/Images/lineor.svg';
import cra from '../../Assets/Images/Client_Right arrow.svg';
import img1 from '../../Assets/Images/Image1m.svg';
import img1sh from '../../Assets/Images/Image1msh.svg';
import img2 from '../../Assets/Images/Image2.svg';
import img2sh from '../../Assets/Images/Image2sh.svg';
import recg from '../../Assets/Images/recgreen.svg';
import devimg from '../../Assets/Images/App development image.svg';
import cliri from '../../Assets/Images/Client_Right arrow.svg';
import clile from '../../Assets/Images/Client_Left arrow.svg';
import recb from '../../Assets/Images/recblue.svg';
import ecom from '../../Assets/Images/E commerce plat  image.svg';
import reco from '../../Assets/Images/recorange.svg';
import esof from '../../Assets/Images/Enterprise Software image.svg';
import recy from '../../Assets/Images/recyellow.svg';
import brand from '../../Assets/Images/Branding image.svg';
import logo11 from '../../Assets/Images/Nlogo_White_footage.svg';
import {ImCross} from 'react-icons/im';
import {useHistory} from 'react-router-dom';
import shaik from "../../Assets/Images/shaik.jpeg"
import anas from "../../Assets/Images/anas.jpeg"


function Services() {

  let history = useHistory();
	const [menu1, setMenu1] = useState(false);

	const menuOpen = () =>{
		 setMenu1(true)
	}

	const menuClose = () => {
	 setMenu1(false)
	}

	const [login, setLogin] = useState(false);

	const loginOpen= () =>{
	 setLogin(true)
}


  return (
    <div>
        <div className='serv'>
            <Container fluid>
              <Row>
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                  <div ><img src={logo1} className='log' style={{width:40}} /></div>
                  <div style={{display:'flex'}}>
                    <div style={{color:'white', marginTop:8, marginRight:15}}>Services</div>
                    <div onClick={menuOpen}><img className='menucls1' src={menu} style={{width:35}} /></div>
                  </div>
                </div>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <img className='grad2' src={ellipse} style={{width:450}}/>
                </Col>
              </Row>
              <Row style={{position: 'relative'}}>
                <Col xs={12} md={6}>
                <div className='css-typing3 wesl'  style={{fontSize: 49, fontWeight:'medium', color:'white'}}>
                  <div>We provide</div>
                  <div className='wor'>solutions for Your Business<span style={{color:'#cc6719', fontSize:'50px', fontWeight: 800}}>.</span></div>
                </div> 
                </Col>
              </Row> 
            </Container>
        </div>

        {menu1 ?
				<div className='menupage'>
          <Row>
					<Col xs={12} md={2}>
					<div style={{display: 'flex', justifyContent:'space-between'}}>
						<div ><img src={logo11} className='log' style={{width:40}} /></div>
					</div>

					<div onClick={() => history.push('/')} style={{fontSize:35, marginTop:40, color: 'white', cursor: 'pointer'}}>Home</div>
					<div onClick={() => history.push('about')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>About</div>
					<div  style={{fontSize:35, marginTop:30, color: 'red',cursor: 'pointer'}}>Services</div>
					<div onClick={() => history.push('contacts')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>Contact</div>
          <div onClick={() => history.push('blog')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>Blog</div>
					<div onClick={loginOpen} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}> Login</div>
					</Col>
					<Col xs={12} md={2}></Col>
					<Col xs={12} md={7}>
          <div style={{marginTop:80}}>
						<Row style={{positon:'relative'}}>
						<Col xs={12} md={4} lg={3}>
							<div><img src={anas}style={{width:150, height:150, borderRadius:80, marginTop:20}}/></div>
						</Col>
							<Col xs={12} md={8} lg={9}>
								<div  style={{fontSize:20, marginTop:30, color: 'white'}}>"You don't learn to walk by following rules.
								You learn by doing and falling over."</div>
								<div style={{fontSize:15, color: 'white', marginTop:5}}>Anas Athanikkal </div> 
							  <div style={{fontSize:14, color: 'white',  marginTop:5}}>CEO</div>
							</Col>
							
						</Row>
						<div style={{marginTop:50}}>
						<Row>
							<Col xs={12} md={8} lg={9}>
								<div  style={{fontSize:20, marginTop:30, color: 'white'}}>"You don't learn to walk by following rules.
								You learn by doing and falling over."</div>
								<div style={{fontSize:15, color: 'white', textAlign:'end'}}>Ibrahim Abdullah M H Al-Hail</div> 
							  <div style={{fontSize:14, color: 'white', textAlign:'end'}}>Chairman</div>
							</Col>
							<Col xs={12} md={4} lg={3} >
								<div><img src={shaik}style={{width:150, height:150, borderRadius:80, marginTop:20}}/></div>
							</Col>
						</Row>
						</div>
						</div>   
					</Col>
					<Col xs={12} md={1}>
					<div onClick={menuClose}>	
							<ImCross size={35} style={{color: 'white'}}/>
					</div>
					</Col>
					</Row>
				{login ?
					<div className='admcl'>
					<div onClick={() => history.push('login')} style={{cursor: 'pointer'}}>Admin Login</div>
					<div onClick={() => history.push('clogin')} style={{marginTop:10,cursor: 'pointer'}}>Client Login</div>
				</div>: null}
				</div>: null}


      <div className='about3'>
        <Row style={{position: 'relative'}}>
            <Col xs={12} md={7}>
                <div> 
                  <img src={lineor} className='lineo' style={{width:650}}/>
                </div>
                <div style={{position: 'relative'}}>
                  <img src={cra} className='crad' />
                </div>
                <div className='webu' style={{fontSize:42, fontWeight:'550', marginTop:225}}>
                  We Build high perfoming application
                </div>
                <p className='paraf' style={{fontSize:18, fontWeight:300, marginTop: 20, marginBottom:50}}>
                    An application that allows users to connect all their social profiles, import
                    their data and process it to show some reports.Building on application
                    which performs well at any scale is becoming a more common
                    expectation of business owners around the world.
                </p>
                    <div className='smdiv' style={{display: 'flex'}}>
                    <BsDot size={30} style={{color:'#ff7a00', marginBottom:3}} />
                    <div style={{marginTop: 2}}>Digital Marketing Solutions for Tomarrow</div>
                    </div>
                    <div className='smdiv' style={{display: 'flex'}}>
                    <BsDot size={30} style={{color:'#ff7a00'}} />
                    <div style={{marginTop: 2}}>Our Talented & Experienced Marketing Agency</div>
                    </div>
                    <div className='smdiv' style={{display: 'flex'}}>
                    <BsDot size={30} style={{color:'#ff7a00'}} />
                    <div style={{marginTop: 2}}>Create Your Own Skin to match Your brand</div>
                    </div>
            </Col> 
            <Col xs={12} md={5}>
              <img src={img1} className='img1' style={{width:'40%'}}/>
              <img src={img1sh} className='img1sh'  style={{width:550}}/>
              
            </Col>
        </Row>
      </div>

      <div className='about4'>
        <Row>
          <Col className='col'  style={{position: 'relative'}} xs={12} md={5}>
              <img src={img2} className='img2' style={{width:'110%'}}/>
              <img src={img2sh} className='img2sh'/>      
          </Col>
          <Col xs={12} md={7}>
              <div className='webi' style={{fontSize:42, fontWeight:'550', marginTop:260, color:'white'}}>
                Custom Front-End and back-End Development services
              </div>
              <p className='parag' style={{fontSize:18, fontWeight:300, marginTop: 20, color:'white'}}>
                  An application that allows users to connect all their social profiles, import
                  their data and process it to show some reports.Building an application
                  which performs well at any scale is becoming a more common expecttion of
                  business owners around the world.
              </p>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00', marginBottom:3}} />
                <div style={{marginTop: 2, color:'white'}}>Agile Approach</div>
                </div>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2, color:'white'}}>Continous delivery</div>
                </div>
                <div className='smdiv' style={{display: 'flex'}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2, color:'white'}}>Test Driven</div>
                </div>
          </Col>    
        </Row>
      </div>


      <div className='picdiv'>
        <Row >
            <Col className='colcol' style={{position: 'relative'}} xs={12} md={1} lg={1}>
               <img className='recg' src={recg} style={{height:'60%'}}/>
            </Col>
            <Col style={{position: 'relative'}} xs={12} md={6} lg={4}>
               <div className='num'>01</div>
               <div className='appnm'>Application</div>
               <div className='depnm'>Development</div>
               <div className='praf' style={{fontSize:18, fontWeight:300}}>Our expert in-house teams are dedicated for
                developing mobile apps to faster your business.
                Our entire work is the result of continous
                research, unceasing creativity and scrupulous execuition
               </div> 
               <div className='wwdar'>
                <div className='wtwdo' style={{fontSize:20}}>What we do?</div>
                <img className="cliri" src={cliri} style={{width:150,marginLeft:210}} />
               </div>  
            </Col>
            <Col xs={12} md={5} lg={7}>
               <img className='devimg' src={devimg} style={{width:'100%'}}/>
            </Col>
        </Row>
       
      </div>

      <div className='picdiv1'>
        <Row >
            <Col className='colimg' xs={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }} lg={{ span: 6, order: 1 }}>
               <img className='devimg1' src={ecom} style={{width:'100%'}}/>
            </Col>
            <Col style={{position: 'relative'}} xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 5, order: 2 }}>
               <div className='num2'>02</div>
               <div className='sing'>Single & Multi seller</div>
               <div className='plat'>E-Commerce Platform</div>
               <div className='paraexp' style={{fontSize:18, fontWeight:300}}>
                Expand business via multifarious digital channels
                all around the world.Our teams of experts have
                in-depth knowledge in various e-commerce web
                development platforms which will give a new look
                to your business
               </div> 
               <div className='wwdal'>
                <img className="clile" src={clile} style={{width:150,marginRight:310}} />
                <div className='wtwedo' style={{fontSize:20}}>What we do?</div>
               </div>
            </Col>
            <Col className='colcol2' style={{position: 'relative'}} md={{ span: 1, order: 3 }} lg={{ span: 1, order: 3 }}>
               <img className='recb' src={recb} style={{height:'60%'}}/>           
            </Col>
        </Row> 
      </div>

      <div className='picdiv'>
        <Row >
            <Col className='colcol' style={{position: 'relative'}} xs={12} md={1} lg={1}>
               <img className='recg' src={reco} style={{height:'60%'}}/>
            </Col>
            <Col style={{position: 'relative'}} xs={12} md={6} lg={4}>
               <div className='num'>03</div>
               <div className='appnm'>Enterprise</div>
               <div className='depnm'>Software</div>
               <div className='smdiv1'>
               <div className='smdiv2' style={{display: 'flex', fontSize:14}}>
                <BsDot size={30} style={{color:'#ff7a00', marginBottom:3}} />
                <div style={{marginTop: 2}}>Agile Approach</div>
                </div>
                <div className='smdiv2' style={{display: 'flex', fontSize:14}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2}}>Continous delivery</div>
                </div>
                <div className='smdiv2' style={{display: 'flex', fontSize:14}}>
                <BsDot size={30} style={{color:'#ff7a00'}} />
                <div style={{marginTop: 2}}>Test Driven</div>
                </div>
               </div>
               <div className='praf' style={{fontSize:18, fontWeight:300}}>
                With Cavernous prowess in full-cycle corporate
                software development, NASSCRIPT improves and 
                automates business with unmatched enterprise 
                applications characterized by 3 specific 
                attributes
               </div> 
               <div className='wwdar'>
                <div className='wtwdo' style={{fontSize:20}}>What we do?</div>
                <img className="cliri" src={cliri} style={{width:150,marginLeft:210}} />
               </div>  
            </Col>
            <Col className="colimg" xs={12} md={5} lg={7}>
               <img className='devimg1' src={esof} style={{width:'100%'}}/>
            </Col>
        </Row>
       
      </div>

      <div className='picdiv1'>
        <Row >
            <Col className='colimg' xs={{ span: 12, order: 2 }} md={{ span: 5, order: 1 }} lg={{ span: 6, order: 1 }}>
               <img className='devimg2' src={brand} style={{width:'100%', marginTop:100}}/>
            </Col>
            <Col style={{position: 'relative'}} xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }} lg={{ span: 5, order: 2 }}>
               <div className='num2'>04</div>
               <div className='sing'>Branding</div>
               <div className='paraexp' style={{fontSize:18, fontWeight:300}}>
               Business can attempt to shape or form the 
               branding of their company or products in many
               ways.A lot of thoughts and efforts goes into
               branding including, naming products, designing 
               logos and ensuring that service is uniform
               throughout the business.
               </div> 
               <div className='wwdal'>
                <img className="clile" src={clile} style={{width:150,marginRight:310}} />
                <div className='wtwedo' style={{fontSize:20}}>What we do?</div>
               </div>
            </Col>
            <Col className='colcol2' style={{position: 'relative'}} md={{ span: 1, order: 3 }} lg={{ span: 1, order: 3 }}>
               <img className='recb' src={recy} style={{height:'60%'}}/>           
            </Col>
        </Row> 
      </div>

    </div>
  )
}

export default Services










      {/* <Container>
             <div style={{padding:30}}>
               <Row>
               <Col xs={12} md={8} lg={8}>
                    <p style={{fontSize:50, fontWeight:'650', color:'black'}}>We help companies<br />
                   transform into<br />
                  progressive growth for<br /> their future</p>
                
                </Col>
               </Row> 
              </div>   
           </Container>
   
           <hr></hr>
            <div>
              <Container>               
                  <div style={{marginTop:20, padding:20}}>
                        <Row>
                         <Col xs={12} md={6} lg={6}>
                           <div style={{padding:10}}>
                           <img style={{width:'100%', borderRadius:5}} src={demo} />
                           </div>
                          </Col>

                          <Col xs={12} md={6} lg={6}>
                          <div style={{marginLeft:40}}>
                          <p style={{fontSize:25, fontWeight:'bold'}}>We Build High Performing<br/>Application</p>
                          <div className='divv' style={{fontSize:16 }}>An application that allows users to connect all their<br/> 
                          social profiles, import their data and process it to show<br/>
                          some reports.Building an application which performs<br/>
                          well at any scale is bocoming a more common<br/>
                          expectation of business owners around the world
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Digital Markrting Solutions for Tomarrow</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Our Talent & Experienced Marketing Agency</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Create Your Own Skin to Match your Brand</div>
                          </div>
                          </div>
                          </Col>
                        </Row>
                  </div>

                  <div style={{marginTop:20, padding:20}}>
                        <Row>
                          <Col xs={12} md={6} lg={6}>
                          <div>
                          <p style={{fontSize:25, fontWeight:'bold'}}>Enterprise Software</p>
                          <div className='divv' style={{fontSize:16 }}>With deep expertise in full-cyle corporate software<br/>
                          development, NASScript improves abd auotomates<br/>
                          business with unmatched enterprise applications.
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Enterprise Resource planning[ERP]</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Customer Relationship Management[CRM]</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Human Resource Management[HRM]</div>
                          </div>
                          </div>
                          </Col>

                          <Col xs={12} md={6} lg={6}>
                           <div style={{padding:10, marginLeft:40}}>
                           <img style={{width:'100%', borderRadius:5}} src={demo} />
                           </div>
                          </Col>
                        </Row>
                  </div>

                  <div style={{marginTop:20, padding:20}}>
                        <Row>
                         <Col xs={12} md={6} lg={6}>
                           <div style={{padding:10}}>
                           <img style={{width:'100%', borderRadius:5}} src={demo} />
                           </div>
                          </Col>

                          <Col xs={12} md={6} lg={6}>
                          <div style={{marginLeft:40}}>
                          <p style={{fontSize:25, fontWeight:'bold'}}>Custom Front-End and<br/>Back-End Development<br/>Services</p>
                          <div className='divv' style={{fontSize:16 }}>Our front-end and back-end development teams work<br/> 
                          on solutions of defferent complexity and deliver full<br/>
                          cycle development services to make our clients' plans<br/>
                         come true.We build reliable and scalable software.
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Agile Approach</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Continous Delivery</div>
                          </div>
                          <div style={{display:'flex', marginTop:10}}>
                          <GiFlowerStar style={{color:'#6d39c4', marginTop:5, marginRight:2}} />
                          <div>Test Driven</div>
                          </div>
                          </div>
                          </Col>
                        </Row>
                  </div>
            
              </Container>                                                    
            </div> */}
  