import React, {useState} from 'react'
import './Contacts.css'
import {Container, Row, Col, Form} from "react-bootstrap"
import menu from '../../Assets/Images/menu icons.svg';
import {ChatBox} from 'react-chatbox-component';
import logo1 from '../../Assets/Images/Nlogo_White_footage.svg';
import line from '../../Assets/Images/Line Design.svg';
import ellips from '../../Assets/Images/Ellipse 3.svg';
import group4 from '../../Assets/Images/Group 4.svg';
import logo11 from '../../Assets/Images/Nlogo_White_footage.svg';
import {ImCross} from 'react-icons/im';
import {useHistory} from 'react-router-dom';
import shaik from "../../Assets/Images/shaik.jpeg"
import anas from "../../Assets/Images/anas.jpeg"

function Contacts() {

  let history = useHistory();
	const [menu1, setMenu1] = useState(false);

	const menuOpen = () =>{
		 setMenu1(true)
	}
  const messages = [
    {
      "text": "Hello there",
      "id": "1",
      "sender": {
        "name": "Ironman",
        "uid": "user1",
        
      },
    },
  ]
	const menuClose = () => {
	 setMenu1(false)
	}

	const [login, setLogin] = useState(false);

	const loginOpen= () =>{
	 setLogin(true)
}

  return (
    <div>
         <div className='conts'>
            <Container fluid>
              <Row>
                <div style={{display: 'flex', justifyContent:'space-between'}}>
                  <div ><img src={logo1} className='log' style={{width:40}} /></div>
                  <div style={{display:'flex'}}>
                    <div style={{color:'white', marginTop:8, marginRight:15}}>Contact</div>
                    <div onClick={menuOpen}><img className='menucls1' src={menu} style={{width:35}} /></div>
                  </div>
                </div>
              </Row>
              
              <Row style={{position:'relative'}}>
                <Col xs={12} md={6}>
                <div className='css-typing4'  style={{fontSize: 49, marginTop:250, fontWeight:'medium', color:'white'}}>
                  <div>Be the part of the best</div>
                  <div className='wor'>software development team<span style={{color:'#7000ff', fontSize:'50px', fontWeight: 800}}>.</span></div>
                </div> 
                <div className='css-typing44'  style={{fontSize: 49, fontWeight:'medium', color:'white'}}>
                  <div>Be the part of the best</div>
                  <div className='wor'>software development team<span style={{color:'#7000ff', fontSize:'50px', fontWeight: 800}}>.</span></div>
                </div> 
                </Col>
             
                <Col xs={12} md={6}> 
                  <img className='grading' src={ellips} style={{width:450}}/>
                </Col>
              </Row>
            </Container>
        </div>

        {menu1 ?
				<div className='menupage'>
          <Row>
					<Col xs={12} md={2}>
					<div style={{display: 'flex', justifyContent:'space-between'}}>
						<div ><img src={logo11} className='log' style={{width:40}} /></div>
					</div>

					<div onClick={() => history.push('/')} style={{fontSize:35, marginTop:40, color: 'white', cursor: 'pointer'}}>Home</div>
					<div onClick={() => history.push('about')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>About</div>
					<div onClick={() => history.push('services')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>Services</div>
					<div  style={{fontSize:35, marginTop:30, color: 'red',cursor: 'pointer'}}>Contact</div>
          <div onClick={() => history.push('blog')} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}>Blog</div>
					<div onClick={loginOpen} style={{fontSize:35, marginTop:30, color: 'white',cursor: 'pointer'}}> Login</div>
					</Col>
					<Col xs={12} md={2}></Col>
					<Col xs={12} md={7}>
          <div style={{marginTop:80}}>
						<Row style={{positon:'relative'}}>
						<Col xs={12} md={4} lg={3}>
							<div><img src={anas}style={{width:150, height:150, borderRadius:80, marginTop:20}}/></div>
						</Col>
							<Col xs={12} md={8} lg={9}>
								<div  style={{fontSize:20, marginTop:30, color: 'white'}}>"You don't learn to walk by following rules.
								You learn by doing and falling over."</div>
								<div style={{fontSize:15, color: 'white', marginTop:5}}>Anas Athanikkal </div> 
							  <div style={{fontSize:14, color: 'white',  marginTop:5}}>CEO</div>
							</Col>
							
						</Row>
						<div style={{marginTop:50}}>
						<Row>
							<Col xs={12} md={8} lg={9}>
								<div  style={{fontSize:20, marginTop:30, color: 'white'}}>"You don't learn to walk by following rules.
								You learn by doing and falling over."</div>
								<div style={{fontSize:15, color: 'white', textAlign:'end'}}>Ibrahim Abdullah M H Al-Hail</div> 
							  <div style={{fontSize:14, color: 'white', textAlign:'end'}}>Chairman</div>
							</Col>
							<Col xs={12} md={4} lg={3} >
								<div><img src={shaik}style={{width:150, height:150, borderRadius:80, marginTop:20}}/></div>
							</Col>
						</Row>
						</div>
						</div>  
					</Col>
					<Col xs={12} md={1}>
					<div onClick={menuClose}>	
							<ImCross size={35} style={{color: 'white'}}/>
					</div>
					</Col>
					</Row>
				{login ?
					<div className='admcl'>
					<div onClick={() => history.push('login')} style={{cursor: 'pointer'}}>Admin Login</div>
					<div onClick={() => history.push('clogin')} style={{marginTop:10,cursor: 'pointer'}}>Client Login</div>
				</div>: null}
				</div>: null}


        <div className="conts1">
          <Row style={{position: 'relative'}}>
            <Col xs={12} md={8}>
             <div className="cshead">Contact us </div>
              <div className="cspara">
                Contact us about anything related to our company or services. We'll try to get  back to you soon as possible
              </div>
            </Col>
            <Col xs={12} md={2}>
              <div className="grp4d">
              <img className="grp4im" src={group4} style={{width:650}} />
              </div> 
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6}>
            <div className="contsm">
            <div className="yemm">Home</div><hr className="hrln" />
            <div className="yemm">E-mail</div><hr className="hrln" />
            <div className="yemm">Mobile</div><hr className="hrln" />
            <div className="yemm">Messages</div><hr className="hrln" />
            <button className="ymbt" >Submit</button>
           </div>
            </Col>

            <Col xs={12} md={6}> 
            {/* <div style={{border: '1px solid white'}}>
            <ChatBox
              messages={messages}
            />
            </div>  */}
            <div className="contsm1">
             <div className="cusjo">Join Our Team</div>
             <div className="cspara">Drop your cv at hr@nasscript.com</div>
             <div className="cspara" onClick={() => history.push('/apply')}>Apply Now</div>
             <div className="cspara">For more details call:</div>
             <div className="cspara">+91 4662966969, +91 9745151015</div>  
             </div>
            </Col>
          </Row>
        </div>
        
    </div>
  )
}

export default Contacts